<template>
  <div class="register">
    <Form class="padding-30 pt-20 pb-20" ref="formValidate" label-position="top" :model="formValidate" :rules="ruleValidate">
      <FormItem label="学员姓名" prop="name">
        <Input size="large" v-model="formValidate.name" placeholder="请输入学员姓名"></Input>
      </FormItem>
      <FormItem label="联系电话" prop="phone">
        <Input size="large" v-model="formValidate.phone" placeholder="请输入联系电话"></Input>
      </FormItem>
      <FormItem label="身份证号" prop="idCard">
        <Input size="large" v-model="formValidate.idCard" placeholder="请输入身份证号"></Input>
      </FormItem>
      <FormItem label="性别" prop="sex">
        <Select size="large" v-model="formValidate.sex" placeholder="请选择性别">
          <Option :value="0">男</Option>
          <Option :value="1">女</Option>
        </Select>
      </FormItem>
      <FormItem label="单位名称" prop="companyName">
        <Input size="large" v-model="formValidate.companyName" placeholder="请输入单位名称"></Input>
      </FormItem>
      <FormItem label="职称">
        <Input size="large" v-model="formValidate.jobName" placeholder="请输入职称"></Input>
      </FormItem>
      <FormItem label="学历">
        <Input size="large" v-model="formValidate.education" placeholder="请输入学历"></Input>
      </FormItem>
      <FormItem :label="`${formValidate.classesId ? `开课时间：${classOptions.filter(item => item.id === formValidate.classesId)[0].classStartDate}` : '培训班次'}`" prop="classesId">
        <Select size="large" v-model="formValidate.classesId" placeholder="请选择班次">
          <Option v-for="item in classOptions" :key="item.id" :value="item.id">{{ item.className }}</Option>
        </Select>
      </FormItem>
      <FormItem label="培训项目" prop="eduProject">
        <Select size="large" v-model="formValidate.eduProject" placeholder="请选择培训项目">
          <Option :value="1">安全初训</Option>
          <Option :value="2">安全复训</Option>
        </Select>
      </FormItem>
      <FormItem label="培训类型" prop="eduType">
        <Select size="large" v-model="formValidate.eduType" placeholder="请选择培训类型">
          <Option :value="1">安全管理员</Option>
          <Option :value="2">企业负责人</Option>
          <Option :value="3">班组长</Option>
        </Select>
      </FormItem>
      <FormItem label="邮箱" prop="email">
        <Input size="large" v-model="formValidate.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem label="微信号">
        <Input size="large" v-model="formValidate.wx" placeholder="请输入微信号"></Input>
      </FormItem>
      <FormItem>
        <Button :loading="loading" size="large" long type="primary" @click="handleSubmit('formValidate')">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Index',
  components: {},
  props: {},
  data () {
    const validateIdCard = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请填写身份证号码'))
      } else {
        callback()
      }
    }
    const validateTel = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('请输入手机号'))
      } else if (value.length !== 11) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      classOptions: [],
      formValidate: {
        jobName: '',
        classesId: '',
        companyName: '',
        eduProject: '',
        eduType: '',
        education: '',
        email: '',
        idCard: '',
        name: '',
        phone: '',
        sex: 0,
        wx: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入学员名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validateTel, rigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        idCard: [
          { required: true, validator: validateIdCard, trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change', type: 'number' }
        ],
        eduType: [
          { required: true, message: '请选择培训类型', trigger: 'change', type: 'number' }
        ],
        eduProject: [
          { required: true, message: '请选择培训项目', trigger: 'change', type: 'number' }
        ],
        classesId: [
          { required: true, message: '请选择班次', trigger: 'change' }
        ]
      }
    }
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {
    // 获取班次
    getClass () {
      axios.get('https://peixun.asdsafe.cn:18001/trainCertifClasses/listAll').then(res => {
        if (res.data.code === 200) {
          this.classOptions = res.data.data.filter(item => item.state === 1 ? Math.round(new Date(item.classEndDate)) > Math.round(new Date()) : Math.round(new Date(item.classStartDate)) > Math.round(new Date()))
        }
      })
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = Object.assign({ type: 1 }, this.formValidate)
          data.code = this.$route.query.code
          axios.post('https://peixun.asdsafe.cn:18001/trainCertifStudent/save', data).then(res => {
            if (res.data.code === 200) {
              this.$router.replace(`/h5-paymentDetail?recordId=${res.data.data}`)
            } else {
              this.$Message.error('报名失败')
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  beforeCreate () {
  },
  created () {
    if (!window.location.href.includes('/#/')) {
      window.location.replace(`${window.location.origin}/${window.location.hash}${window.location.search}`)
    }
  },
  beforeMount () {
  },
  mounted () {
    this.getClass()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
  /deep/.ivu-form .ivu-form-item-label {
    font-size: 16px;
  }
  .register {
    width: 100vw;
    height: 100vh;
    background: url("~./img/bg.png");
    background-size: 100% 100%;
    overflow: auto;
  }
</style>
